export const languageOptions = [
  {
    id: 1,
    name: "Python (3.8.1)",
    label: "Python (3.8.1)",
    value: "python",
  },
  {
    id: 2,
    name: "Go (1.13.5)",
    label: "Go (1.13.5)",
    value: "go",
  }
];
