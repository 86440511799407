import React from "react";

const OutputWindow = ({ outputDetails }) => {
  var binaryData = [];
  binaryData.push(outputDetails);
  let archi;
  if (outputDetails) {
    archi = (<img src={URL.createObjectURL(new Blob(binaryData, {type: "image/png"}))} alt="Architecture image"/>)
  } else {
    archi = (<img src={require('./../clustered_web_services.png')} alt="Architecture image"/>)
  }
  return (
    <>
      <div className="w-full bg-none h-full shadow-[1px_1px_1px_1px_rgba(0,0,0)] rounded-md text-white font-normal text-sm overflow-x-auto overflow-y-auto" >
        {archi}
      </div>
    </>
  );
};

export default OutputWindow;
