import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../utils/general";
import { languageOptions } from "../constants/languageOptions";
import { defaultDiagram } from "../constants/codeSample"

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import Footer from "./Footer";
import OutputWindow from "./OutputWindow";
import { PlayIcon, ArrowTopRightOnSquareIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'


const Landing = () => {
  const [code, setCode] = useState(defaultDiagram);
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(languageOptions[0]);

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");
  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
      handleCompile();
    }
  }, [ctrlPress, enterPress]);
  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };
  const handleCompile = () => {
    // setProcessing(true);
    const formData = {
      name: language.name,
      // encode source code in base64
      payload: window.btoa(code),
    };
    const options = {
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };
    console.log("process.env", process.env)
    const status = 301
    axios
      .request(options)
      .then(function (response) {
        setOutputDetails(response.data);
        return;
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        if (status === 429) {

          showErrorToast(
            `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
            10000
          );
        }
        showErrorToast();
      });
  };

  const handleDownloadFile = () => {

    if (outputDetails) {
      const href = URL.createObjectURL(outputDetails);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'architecture.png'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }

  };
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <div className="flex">

        <div className="flex flex-row w-1/2 px-3 py-3 w-1/2">
          <button
            onClick={handleCompile}
            disabled={!code}
            className={classnames(
              "flex flex-row border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
              !code ? "opacity-50" : ""
            )}
          >
            <PlayIcon className="size-4"/><span className="px-2">Generate diagram</span>
          </button>
        </div>
        <div className="flex flex-row w-1/2 px-3 py-3 w-1/2">
          <button
            onClick={handleDownloadFile}
            disabled={!code}
            className={classnames(
              "flex flex-row border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
              !code ? "opacity-50" : ""
            )}
          >
          <ArrowDownTrayIcon className="size-4"/> <span className="px-2">Download image</span>
          </button>

          <button
            onClick={()=> window.open("https://diagrams.mingrammer.com/docs/guides/diagram", "_blank")}
            className={classnames(
              "flex flex-row border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] ml-4 px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
              !code ? "opacity-50" : ""
            )}
          >
          <ArrowTopRightOnSquareIcon className="size-4"/> <span className="px-2">Diagrams documentation</span>
          </button>
        </div>
      </div>
      <div className="flex h-screen pb-40 pt-1">
        <div className="w-1/2 h-full pl-2 pr-2">
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language={language?.value}
            theme={theme.value}
          />
        </div>
        <div className="w-1/2 h-full pr-2">
          <OutputWindow outputDetails={outputDetails} />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Landing;
